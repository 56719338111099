/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import DoneIcon from '@mui/icons-material/Done';
import MenuItem from '@mui/material/MenuItem';

export const NotificationItemComponent = notificationItemData => {
  const {
    id,
    isViewed,
    createdAt,
    message,
    title,
    level,
    onClick,
  } = notificationItemData;
  return (
    <MenuItem
      key={id}
      onClick={() => onClick(notificationItemData)}
      className={classNames('notification', isViewed ? 'viewed' : '', level)}
    >
      <span className="notificationHeader">
        <b>{title || 'n/a'}</b>
        {isViewed ? <DoneIcon className="iconRight" /> : ''}
        <i>{moment(createdAt).fromNow()}</i>
      </span>
      <span>{message}</span>
    </MenuItem>
  );
};

export default NotificationItemComponent;