import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const SideBar = ({ isLocked, toggleLock, className, children }) => (
  <Drawer
    variant="permanent"
    data-tid="mainMenu"
    className={cn(
      'activityBar',
      className,
      { locked: isLocked }
    )}
  >
    <IconButton
      size="large"
      edge="start"
      aria-label="menu"
      disableRipple
      color="white"
      onClick={() => toggleLock()}
      sx={{ display: 'flex', justifyContent: 'flex-end', py: '6px', px: '20px' }}
    >
      {isLocked ? <ChevronLeftIcon /> : <MenuIcon />}
    </IconButton>
    {children}
  </Drawer>
);

SideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(
      PropTypes.element.isRequired,
    ).isRequired,
  ]).isRequired,
  isLocked: PropTypes.bool.isRequired,
  toggleLock: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SideBar;
