import React, { useState, useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'react-redux';
import { notificationPropType } from '../../utils/customPropTypes';
import { NOTIFICATION_VARIANTS } from '../../utils/constants';
import { clearNotification } from '../../store/actions/app';

const StyledSnackbarContent = styled(SnackbarContent, {
  shouldForwardProp: (prop) => prop !== 'notificationVariant',
})(({ theme, notificationVariant }) => {
  const variants = {
    success: '#0ac700',
    error: theme.palette.error.dark,
    info: theme.palette.primary.dark,
    warning: '#0ac700',
  };
  return { backgroundColor: variants[notificationVariant] };
});

const UINotification = ({ notification: notificationFromProps, done }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({});
  const prevTime = useRef(null);

  //set notification when component is first mounted
  useEffect(() => {
    const { time, shown } = notificationFromProps;
    if (time && !shown) {
      prevTime.current = notificationFromProps?.time;
      setNotification(notificationFromProps);
      setOpen(true);
      done();
    }
  }, []);

  // Clear notification after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => dispatch(clearNotification()), 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    let timeout;
    const { time, shown } = notificationFromProps;
    if (time && !shown) {
      if (time !== prevTime.current) {
        prevTime.current = time;
        // hide current and open up the next one
        setOpen(false);
        timeout = setTimeout(() => {
          setNotification(notificationFromProps);
          setOpen(true);
          done();
        }, 200); // wait for the closing transition
      } else {
        setNotification(notificationFromProps);
        setOpen(true);
      }
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [notificationFromProps]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearNotification());
    setOpen(false);
  };

  const Icon = useMemo(() => {
    switch (notificationFromProps.variant) {
      case NOTIFICATION_VARIANTS.SUCCESS:
        return CheckCircleIcon;
      case NOTIFICATION_VARIANTS.ERROR:
        return WarningIcon;
      case NOTIFICATION_VARIANTS.INFO:
        return ErrorIcon;
      case NOTIFICATION_VARIANTS.WARNING:
        return InfoIcon;
      default:
        return null;
    }
  }, [notificationFromProps]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={notification.autoHide ? 5000 : null}
      onClose={handleClose}
    >
      <StyledSnackbarContent
        notificationVariant={notification.variant}
        message={
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Icon
              sx={{
                fontSize: 20,
                opacity: 0.9,
                marginRight: 1,
              }}
            />
            {notification.message}
          </Box>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            size="large"
            sx={{
              padding: 0.5,
            }}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

UINotification.propTypes = {
  notification: notificationPropType.isRequired,
  done: PropTypes.func.isRequired,
};

export default UINotification;
