import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ClientOrders = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14.1562C13.1909 14.1562 14.1562 13.1909 14.1562 12C14.1562 10.8091 13.1909 9.84375 12 9.84375C10.8091 9.84375 9.84375 10.8091 9.84375 12C9.84375 13.1909 10.8091 14.1562 12 14.1562Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9248 7.38277L15.3063 4.09377C13.1817 3.26619 10.8258 3.25543 8.69376 4.06358V4.04777L10.0694 7.35402C11.3027 6.8447 12.6881 6.84781 13.9191 7.36264L13.9248 7.38277Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6875 10.0737L4.09375 8.69374C3.26453 10.818 3.25377 13.1745 4.06356 15.3062H4.04631L7.52793 13.932C6.95007 12.6976 7.0096 11.2591 7.6875 10.0766V10.0737Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0738 16.3125L8.69376 19.9062C10.8137 20.7314 13.1641 20.7427 15.2919 19.9379V19.9537L13.9162 16.4721C12.6835 17.0484 11.2474 16.9889 10.0666 16.3125L10.0738 16.3125Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3125 13.9277L19.9062 15.3062C20.734 13.1818 20.7453 10.826 19.9379 8.69374H19.9522L16.4721 10.0694C17.0492 11.3035 16.9897 12.7412 16.3125 13.9234V13.9277Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ClientOrders;
