import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Tasks = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.207 4.5C21.3946 4.68749 21.4999 4.9418 21.5 5.207V22.5C21.5 23.0523 21.0523 23.5 20.5 23.5H3.5C2.94772 23.5 2.5 23.0523 2.5 22.5V1.5C2.5 0.947715 2.94772 0.5 3.5 0.5H16.793C17.0582 0.500057 17.3125 0.605451 17.5 0.793L21.207 4.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7.771L7.083 8.853C7.18679 8.95687 7.331 9.00987 7.47735 8.99793C7.62371 8.98599 7.75742 8.91032 7.843 8.791L10 5.771"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 7.5H17.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12.771L7.083 13.853C7.18679 13.9569 7.331 14.0099 7.47735 13.9979C7.62371 13.986 7.75742 13.9103 7.843 13.791L10 10.771"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 12.5H17.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 17.771L7.083 18.853C7.18679 18.9569 7.331 19.0099 7.47735 18.9979C7.62371 18.986 7.75742 18.9103 7.843 18.791L10 15.771"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 17.5H17.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Tasks;
