import { connect } from 'react-redux';
import { compose } from 'redux';
import Notifications from './NotificationsComponent';
import { countActiveNotifications, getNotifications } from '../../store/selectors/notifications';
import { viewNotification, readAllNotifications, clearAllNotifications } from '../../store/thunks/notifications';

const mapDispatchToProps = {
  viewNotification,
  readAllNotifications,
  clearAllNotifications,
};

const mapStateToProps = state => ({
  actives: countActiveNotifications(state),
  notifications: getNotifications(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Notifications);
