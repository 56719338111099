import PropTypes from 'prop-types';

export const notificationPropType = PropTypes.shape({
  time: PropTypes.number,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  shown: PropTypes.bool,
  autoHide: PropTypes.bool,
});

export const metaDataPropType = PropTypes.shape({
  title: PropTypes.string,
  productPath: PropTypes.string,
  genre: PropTypes.array,
  director: PropTypes.string,
  country: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.string,
  runtime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // for the default value (empty string). application logic is reponsible for saving number value
  ]),
  episode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  season: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  episodeTitle: PropTypes.string,
  seasonTitle: PropTypes.string,
});

const customPropTypes = {
  notificationPropType,
  metaDataPropType,
};

export default customPropTypes;
