import { compose, pickProperty } from '../../utils/utils';

const selectApp = state => state.app;

export const selectNotification = compose(selectApp, pickProperty('notification'));
export const selectNews = compose(selectApp, pickProperty('news'));
export const selectSliderMenuLocked = compose(selectApp, pickProperty('sliderMenuLocked'));
export const selectCountries = compose(selectApp, pickProperty('countries'));
export const selectCountryList = compose(selectCountries, pickProperty('rows'));
export const selectSessionCountdown = compose(selectApp, pickProperty('sessionCountdown'));
export const selectSessionCounter = compose(selectSessionCountdown, pickProperty('counter'));
export const selectSessionCounterTimeoutID = compose(selectSessionCountdown, pickProperty('timeoutID'));
export const selectFlags = compose(selectApp, pickProperty('flags'));
export const selectWorkflowVersion = compose(selectApp, pickProperty('workflowVersion'));
export const selectWorkflowVersionLoading = compose(selectApp, pickProperty('workflowVersionLoading'));

const app = {
  selectNotification,
  selectNews,
  selectSliderMenuLocked,
  selectCountries,
  selectCountryList,
  selectSessionCounter,
  selectSessionCounterTimeoutID,
  selectFlags,
};

export default app;
