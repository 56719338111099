import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Companies = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      d="M21.5911 6.19658H21.2227V5.53905C21.2227 4.7285 20.5906 4.06905 19.8137 4.06905H9.2847L8.43779 3.1266C8.36503 3.04564 8.26372 3 8.15757 3H4.18645C3.40932 3 2.77747 3.65945 2.77747 4.47V6.19682H2.40905C1.63215 6.19682 1.00008 6.85626 1.00008 7.66682C1.00008 7.67787 1.00054 7.68892 1.00146 7.69997L2.02498 20.5475C2.03442 21.3499 2.6628 22 3.43395 22H20.5664C21.3378 22 21.9662 21.3499 21.9754 20.5475L22.9987 7.69997C22.9996 7.68916 23.0001 7.67787 23.0001 7.66682C23.0001 6.85578 22.3678 6.19658 21.5911 6.19658ZM3.54609 4.46976C3.54609 4.10124 3.83346 3.80167 4.18668 3.80167H7.99109L8.83799 4.74411C8.91052 4.82483 9.01207 4.87072 9.11822 4.87072H19.8139C20.1672 4.87072 20.4543 5.17053 20.4543 5.53881V6.19634H3.54609V4.46976ZM21.2082 20.4961C21.2072 20.5072 21.2068 20.5187 21.2068 20.5295C21.2068 20.898 20.9197 21.1979 20.5664 21.1979H3.43395C3.08096 21.1979 2.79359 20.898 2.79359 20.5295C2.79359 20.5187 2.79313 20.5072 2.79221 20.4961L1.76892 7.65168C1.77652 7.29013 2.06067 6.99824 2.40928 6.99824H21.5913C21.9399 6.99824 22.2241 7.29013 22.2315 7.65168L21.2082 20.4961Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </SvgIcon>
);

export default Companies;
