import { saveNotificationTimeout, saveNotifications, clearNotificationTimeout } from '../actions/notifications';
import { selectIsAuthenticated } from '../selectors/authentication';
import { getNotifications } from '../selectors/notifications';

export const startPolling = () => async (dispatch, getState, { notificationsClient, publicRuntimeConfig }) => {
  const isAuthenticated = selectIsAuthenticated(getState());
  if (!isAuthenticated) return dispatch(clearNotificationTimeout());
  const { pollingTimeout } = publicRuntimeConfig;
  try {
    const notificaitons = await dispatch(notificationsClient.getNotifications());
    const oldNotifications = getNotifications(getState());
    if (
      (notificaitons.length > 0 && oldNotifications.length === 0) ||
      (notificaitons.length > 0 && notificaitons[0].id !== oldNotifications[0].id) ||
      (notificaitons.filter(({ isViewed }) => isViewed).length !== oldNotifications.filter(({ isViewed }) => isViewed).length)
    ) {
      dispatch(saveNotifications(notificaitons));
    }
  } catch (e) {
    console.log('Failed to fetch notifications');
  } finally {
    const id = setTimeout(() => dispatch(startPolling()), pollingTimeout);
    dispatch(saveNotificationTimeout(id));
  }
};

export const viewNotification = id => async (dispatch, _, { notificationsClient }) => {
  dispatch(clearNotificationTimeout());
  try {
    await dispatch(notificationsClient.updateNotification(id));
  } finally {
    dispatch(startPolling());
  }
};

export const readAllNotifications = () => async (dispatch, _, { notificationsClient }) => {
  try {
    await dispatch(notificationsClient.readAllNotifications());
    const notificaitons = await dispatch(notificationsClient.getNotifications());
    dispatch(saveNotifications(notificaitons));
  } catch (e) {
    console.error(e);
  }
};

export const clearAllNotifications = ({ onlyRead = false } = {}) => async (dispatch, _, { notificationsClient }) => {
  try {
    await dispatch(notificationsClient.clearAllNotifications(onlyRead ? { isViewed: true } : {}));
    const notificaitons = await dispatch(notificationsClient.getNotifications());
    dispatch(saveNotifications(notificaitons));
  } catch (e) {
    console.error(e);
  }
};
