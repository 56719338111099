import React from 'react';
import Link from 'next/link';
import Button from '@mui/material/Button';

const LinkButton = ({ href, children, sx }) => (
  <Link href={href} passHref>
    <Button variant="contained" color="primary" sx={sx}>{children}</Button>
  </Link>
);

export default LinkButton;
