import React from 'react';
import PropTypes from 'prop-types';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import Link from 'next/link';
import clsx from 'clsx';
import { styled } from '@mui/material';

const BbfcListItem = styled(MuiListItemButton)(({ theme: { bbfcTheme } }) => ({
  height: '56px',
  color: bbfcTheme.palette.grey85.main,
  borderLeft: '4px solid transparent',
  '&:hover': {
    borderLeftColor: bbfcTheme.palette.grey85.main,
  },
  '&.Mui-selected': {
    color: bbfcTheme.palette.green.main,
    borderLeftColor: bbfcTheme.palette.green.main,
    backgroundColor: bbfcTheme.palette.grey10.main,
  },
}));

const BbfcListItemText = styled(MuiListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'inherit',
  },
}));

const BbfcListItemIcon = styled(MuiListItemIcon)(() => ({
  color: 'inherit',
  margin: 0,
}));

const PREFIX = 'MenuItem';

const classes = {
  hide: `${PREFIX}-hide`,
  display: `${PREFIX}-display`,
};

const Root = styled('div')(() => ({
  [`& .${classes.hide}`]: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  [`& .${classes.display}`]: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 1s, opacity 0.5s linear',
  },
}));

const BbfcMenuItem = ({
  label,
  IconComponent,
  href,
  target = '_self',
  external,
  active,
  sliderMenuLocked,
}) => (
  <Root>
    <Link href={href} key={label}>
      <BbfcListItem
        key={label}
        href={href}
        selected={active}
        data-tid={label?.replace(/\s+/g, '')}
        target={target}
        component={external ? 'a' : 'div'}
      >
        <BbfcListItemIcon><IconComponent /></BbfcListItemIcon>
        <BbfcListItemText
          className={clsx(sliderMenuLocked ? classes.display : classes.hide)}
          primary={label}
        />
      </BbfcListItem>
    </Link>
  </Root>
);

BbfcMenuItem.defaultProps = {
  target: '_self',
  external: false,
  label: '',
};

BbfcMenuItem.propTypes = {
  label: PropTypes.string,
  IconComponent: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.string,
  external: PropTypes.bool,
  active: PropTypes.bool,
  sliderMenuLocked: PropTypes.bool,
};

export default BbfcMenuItem;
