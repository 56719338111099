import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startPolling } from '../store/thunks/notifications';
import { selectIsAuthenticated } from '../store/selectors/authentication';

export const useStartNotificationPolling = () => {
  const dispatch = useDispatch();
  const [polling, setPolling] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated && !polling) {
      dispatch(startPolling());
      setPolling(true);
    }
  }, [polling, isAuthenticated, dispatch]);
};