import React, { useContext } from 'react';
import getConfig from 'next/config';

import PermissionContext from '../../pages/_permission';
import EnvironmentIndicator from './EnvironmentIndicator';

const { publicRuntimeConfig: { env, configEnv, version, displayVersion, apiUrl } } = getConfig();

const Environment = () => {
  const { isAdmin } = useContext(PermissionContext);

  if (!displayVersion || !env || !version) return null;

  const shouldDisplayIndicator = configEnv === 'horizon-production' ? isAdmin && displayVersion : displayVersion;
  if (!shouldDisplayIndicator) return null;

  return (
    <a href={`${apiUrl}/version`} target="_blank" rel="noopener noreferrer" className="versionLink">
      <EnvironmentIndicator env={configEnv} envLabel={env} version={version} />
    </a>
  );
};

export default Environment;
