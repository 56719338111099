import React, { PureComponent } from 'react';
import Router from 'next/router';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownCircle from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpCircle from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import Fade from '@mui/material/Fade';
import BusinessIcon from '@mui/icons-material/Business';
import DialogModal from '../../common/DialogModal';

class UserCompanyMenuMultiple extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpenMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleCompanySwitch = (companyId) => {
    this.props.onChange(companyId);
    this.handleCloseMenu();
    Router.push('/');
  }

  render() {
    const { anchorEl } = this.state;
    const { company = {}, companies = [] } = this.props;
    const isOpen = Boolean(anchorEl);

    return (
      <div className="loggedUserMenu mr-1">
        <Button
          disableRipple
          aria-owns={isOpen ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
          className={cn('loggedUserMenuButton', { hidden: isOpen })}
          data-tid="userCompanyMenuButton"
        >
          <Avatar
            className="menuAvatar"
            src={company.logo && `/assets/images/company-logos/${company.logo}`}
          >
            <BusinessIcon style={{ fontSize: 15 }} />
          </Avatar>
          <span className="noWrap"><span className="company">{company && company.companyName}</span></span>
          <KeyboardArrowDownCircle className="menuIcon" />
        </Button>

        <Popover
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={isOpen}
          onClose={this.handleCloseMenu}
          className="userMenu"
          TransitionComponent={Fade}
          style={{ marginTop: '-3px' }}
        >
          <div className="menuUser" onClick={this.handleCloseMenu} data-tid="userCompanyMenuClose">
            <Avatar
              className="menuAvatar"
              src={company.logo && `/assets/images/company-logos/${company.logo}`}
            >
              <BusinessIcon style={{ fontSize: 15 }} />
            </Avatar>
            {company && company.companyName}
            <KeyboardArrowUpCircle className="menuIcon" />
          </div>
          <div className="userMenuActions">
            {companies
              .filter(c => c.id !== company.id)
              .map(c => (
                <DialogModal
                  key={c.id}
                  id="switch-company"
                  title="Switch company"
                  agreeLabel="Switch"
                  disagreeLabel="Cancel"
                  description={`Are you sure you would like to switch to ${c.companyName} company?`}
                  onAgree={() => this.handleCompanySwitch(c.id)}
                  onDisagree={() => this.handleCloseMenu()}
                  withAgree
                  withDisagree
                  toggle={show => (
                    <MenuItem onClick={() => show()}>
                      <ListItemIcon>
                        <Avatar
                          style={{ width: 30, height: 30 }}
                          src={c.logo && `/assets/images/company-logos/${c.logo}`}
                        >
                          <BusinessIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText inset primary={c.companyName} />
                    </MenuItem>
                  )}
                />
              ))}
          </div>
        </Popover>
      </div >
    );
  }
}

UserCompanyMenuMultiple.propTypes = {
  company: PropTypes.object,
  companies: PropTypes.array,
  onChange: PropTypes.func,
};

export default UserCompanyMenuMultiple;
