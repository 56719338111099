import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Submissions = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 13C1.5 12.4477 1.94772 12 2.5 12H16.5C17.0523 12 17.5 12.4477 17.5 13V21C17.5 21.5523 17.0523 22 16.5 22H2.5C1.94772 22 1.5 21.5523 1.5 21V13Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 10C6.70914 10 8.5 8.20914 8.5 6C8.5 3.79086 6.70914 2 4.5 2C2.29086 2 0.5 3.79086 0.5 6C0.5 8.20914 2.29086 10 4.5 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 10C14.3807 10 15.5 8.88071 15.5 7.5C15.5 6.11929 14.3807 5 13 5C11.6193 5 10.5 6.11929 10.5 7.5C10.5 8.88071 11.6193 10 13 10Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 18.5L22.577 20.615C22.7809 20.7316 23.0331 20.723 23.2286 20.5927C23.424 20.4625 23.5291 20.2331 23.5 20V14C23.5291 13.767 23.424 13.5375 23.2286 13.4073C23.0331 13.277 22.7809 13.2684 22.577 13.385L17.5 15.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Submissions;
