import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Users = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1908 12C15.3665 12 17.9408 9.42564 17.9408 6.25C17.9408 3.07436 15.3665 0.5 12.1908 0.5C9.01519 0.5 6.44083 3.07436 6.44083 6.25C6.44083 9.42564 9.01519 12 12.1908 12Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 23.5C3 18.2695 7.24013 14.0294 12.4706 14.0294C17.7011 14.0294 21.9412 18.2695 21.9412 23.5H3Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Users;
