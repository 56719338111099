import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MyTasks = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      d="M18 6.99899L11 16.499L6 12.499"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1.49899C0.5 0.946708 0.947715 0.498993 1.5 0.498993H22.5C23.0523 0.498993 23.5 0.946708 23.5 1.49899V22.499C23.5 23.0513 23.0523 23.499 22.5 23.499H1.5C0.947715 23.499 0.5 23.0513 0.5 22.499V1.49899Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default MyTasks;
