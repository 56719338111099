import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import {
  selectSessionCounter,
  selectSessionCounterTimeoutID,
} from '../../store/selectors/app';
import { logoutUser as logoutUserThunk } from '../../store/thunks/authentication';
import {
  startSessionCountdown as startSessionCountdownThunk,
} from '../../store/thunks/app';

import SimpleDialog from '../common/SimpleDialog';

class RemainingTime extends PureComponent {
  componentDidMount() {
    const {
      sessionCounterTimeoutID,
      startSessionCountdown,
    } = this.props;
    if (!sessionCounterTimeoutID) startSessionCountdown();
  }

  //TODO: [petya] just let the background job handle the click event and refresh the token?
  handleAgree = (e) => e;

  render() {
    const {
      sessionCounter,
      logoutUser,
    } = this.props;

    return (
      <Fragment>
        <p className="sessionRemainingTime"><span className="label noWrap">Remaining time: </span><HourglassEmptyIcon className="hourglass iconSmall iconLeft iconInline" />  <b>{moment.utc(sessionCounter).format('HH:mm:ss')}</b></p>
        <SimpleDialog
          agreeLabel="Continue"
          disagreeLabel="Logout"
          description={`Your session will be expire due to inactivity within ${moment.utc(sessionCounter).format('HH:mm:ss')}. Please click the Continue button to prevent the logout.`}
          title="Warning"
          onAgree={this.handleAgree}
          onDisagree={logoutUser}
          show={sessionCounter < 60 * 1000}
        />
      </Fragment>
    );
  }
}

RemainingTime.propTypes = {
  sessionCounter: PropTypes.number.isRequired,
  sessionCounterTimeoutID: PropTypes.number,
  logoutUser: PropTypes.func.isRequired,
  startSessionCountdown: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sessionCounter: selectSessionCounter(state),
  sessionCounterTimeoutID: selectSessionCounterTimeoutID(state),
});

const mapDispatchToProps = {
  logoutUser: logoutUserThunk,
  startSessionCountdown: startSessionCountdownThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemainingTime);
