import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import BusinessIcon from '@mui/icons-material/Business';

const UserCompanyMenuSingle = ({ company = {} }) => (
  <div className="loggedUserMenu mr-1">
    <Button
      disableRipple
      aria-haspopup="true"
      className={cn('loggedUserMenuButton')}
      data-tid="userCompanyMenuButton"
    >
      <Avatar
        className="menuAvatar"
        src={company.logo && `/assets/images/company-logos/${company.logo}`}
      >
        <BusinessIcon style={{ fontSize: 15 }} />
      </Avatar>
      <span className="noWrap"><span className="company">{company && company.companyName}</span></span>
    </Button>
  </div >
);

UserCompanyMenuSingle.propTypes = {
  company: PropTypes.object,
};

export default UserCompanyMenuSingle;
