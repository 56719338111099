import React from 'react';
import PropTypes from 'prop-types';
import UserCompanyMenuSingle from './UserCompanyMenuSingle';
import UserCompanyMenuMultiple from './UserCompanyMenuMultiple';

const UserCompanyMenu = ({ loggedInUser, onChange }) => {
  const { company = {}, companies = [] } = loggedInUser;
  if (companies.length === 1) return <UserCompanyMenuSingle company={company} />;
  if (companies.length > 1) return <UserCompanyMenuMultiple company={company} companies={companies} onChange={onChange} />;
  return null;
};

UserCompanyMenu.propTypes = {
  loggedInUser: PropTypes.shape({
    comapny: PropTypes.object,
    companies: PropTypes.array,
  }),
  onChange: PropTypes.func,
};

export default UserCompanyMenu;
