import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Facilities = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.710007C16.1421 0.710007 19.5 4.06787 19.5 8.21001C19.5 11.757 14 20.591 12.421 23.06C12.3291 23.2035 12.1704 23.2903 12 23.2903C11.8296 23.2903 11.6709 23.2035 11.579 23.06C10 20.592 4.5 11.757 4.5 8.21001C4.5 4.06787 7.85786 0.710007 12 0.710007Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.21C13.6569 11.21 15 9.86686 15 8.21001C15 6.55315 13.6569 5.21001 12 5.21001C10.3431 5.21001 9 6.55315 9 8.21001C9 9.86686 10.3431 11.21 12 11.21Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Facilities;
