import React, { PureComponent } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownCircle from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpCircle from '@mui/icons-material/KeyboardArrowUp';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import Fade from '@mui/material/Fade';
import DialogModal from '../common/DialogModal';

class LoggedUserMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleOpenMenu = event => this.setState({ anchorEl: event.currentTarget });

  handleCloseMenu = () => this.setState({ anchorEl: null });

  handleLogout = (showModal) => {
    if (this.props.preventLogout) return showModal();
    this.props.onLogout();
  }

  handleEditMyProfile = () => {
    const { router, loggedInUser: { id } } = this.props;
    router.push({
      pathname: '/users/[id]/edit',
      query: { id },
    });
  }

  handleMyProfile = () => {
    const { router, loggedInUser: { id } } = this.props;
    router.push({
      pathname: '/users/[id]',
      query: { id },
    });
  }

  render() {
    const { anchorEl } = this.state;
    const { onLogout, loggedInUser: { firstName, lastName, roles = [], isAdmin, profilePicture } } = this.props;
    const isOpen = Boolean(anchorEl);

    return (
      <div className="loggedUserMenu mr-1">
        <Button
          disableRipple
          aria-owns={isOpen ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleOpenMenu}
          className={`loggedUserMenuButton ${isOpen && 'hidden'}`}
          data-tid="userMenuButton"
        >
          <Avatar
            className="menuAvatar"
            src={profilePicture && `/assets/images/profile-pictures/${profilePicture}`}
          >
            {`${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`}
          </Avatar>
          <span className="noWrap">{firstName} {lastName}</span>
          <KeyboardArrowDownCircle className="menuIcon" />
        </Button>

        <Popover
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={isOpen}
          onClose={this.handleCloseMenu}
          className="userMenu"
          TransitionComponent={Fade}
          style={{ marginTop: '-3px' }}
        >
          <div className="menuUser" onClick={this.handleCloseMenu} data-tid="userMenuClose">
            <Avatar
              className="menuAvatar"
              src={profilePicture && `/assets/images/profile-pictures/${profilePicture}`}
            >
              {`${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`}
            </Avatar>
            {firstName} {lastName}
            <KeyboardArrowUpCircle className="menuIcon" />
          </div>
          {!isAdmin && roles && (
            <div className="userRoles">
              <span>User role(s):</span>
              <ul>
                {roles.length === 0
                  ? <li>You do not have any permission!</li>
                  : roles.map(role => <li key={role}>{role}</li>)}
              </ul>
            </div>
          )}
          {isAdmin && <p className="userAdmin">User Role: <b>System Admin</b></p>}
          <div className="userMenuActions">
            <MenuItem onClick={this.handleMyProfile} data-tid="userMenuMyProfile">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText inset primary="My profile" />
            </MenuItem>
            <MenuItem onClick={this.handleEditMyProfile} data-tid="userMenueditMyProfile">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText inset primary="Edit my profile" />
            </MenuItem>
            <DialogModal
              id="reset-notifications"
              title="Are you sure you want to log out?"
              agreeLabel="Logout"
              disagreeLabel="Cancel"
              description="If you log out, the file upload(s) in the background will be terminated."
              onAgree={() => onLogout()}
              withAgree
              withDisagree
              toggle={show => (
                <MenuItem onClick={() => this.handleLogout(show)} data-tid="userMenulogOut">
                  <ListItemIcon>
                    <PowerSettingsNewIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Logout" />
                </MenuItem>
              )}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

LoggedUserMenu.propTypes = {
  router: PropTypes.object.isRequired,
  loggedInUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    company: PropTypes.object,
    roles: PropTypes.array,
    isAdmin: PropTypes.bool,
    profilePicture: PropTypes.string,
  }),
  onLogout: PropTypes.func.isRequired,
  preventLogout: PropTypes.bool.isRequired,
};

export default withRouter(LoggedUserMenu);
