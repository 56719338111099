import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SimpleDialog = ({ title, description, onAgree, agreeLabel, disagreeLabel, show, onDisagree, showDisagreeButton }) => (
  <Dialog open={show}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {showDisagreeButton &&
        <Button onClick={() => onDisagree()} color="secondary">
          {disagreeLabel}
        </Button>
      }
      <Button onClick={() => onAgree()} color="primary" autoFocus>
        {agreeLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

SimpleDialog.defaultProps = {
  agreeLabel: 'Agree',
  disagreeLabel: 'Disagree',
  showDisagreeButton: true,
};

SimpleDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func.isRequired,
  agreeLabel: PropTypes.string,
  disagreeLabel: PropTypes.string,
  showDisagreeButton: PropTypes.bool,
  show: PropTypes.bool.isRequired,
};

export default SimpleDialog;
