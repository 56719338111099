import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Dashboard = () => (
  <SvgIcon style={{ fill: 'none' }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 17.5C0.5 16.9477 0.947715 16.5 1.5 16.5H9.5C10.0523 16.5 10.5 16.9477 10.5 17.5V22.5C10.5 23.0523 10.0523 23.5 9.5 23.5H1.5C0.947716 23.5 0.5 23.0523 0.5 22.5V17.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 22.5C23.5 23.0523 23.0523 23.5 22.5 23.5H14.5C13.9477 23.5 13.5 23.0523 13.5 22.5L13.5 11.5C13.5 10.9477 13.9477 10.5 14.5 10.5L22.5 10.5C23.0523 10.5 23.5 10.9477 23.5 11.5V22.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 6.5C23.5 7.05228 23.0523 7.5 22.5 7.5L14.5 7.5C13.9477 7.5 13.5 7.05228 13.5 6.5V1.5C13.5 0.947715 13.9477 0.5 14.5 0.5L22.5 0.5C23.0523 0.5 23.5 0.947715 23.5 1.5V6.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H9.5C10.0523 0.5 10.5 0.947715 10.5 1.5V12.5C10.5 13.0523 10.0523 13.5 9.5 13.5H1.5C0.947716 13.5 0.5 13.0523 0.5 12.5V1.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Dashboard;
