import React from 'react';
import PropTypes from 'prop-types';

const EnvironmentIndicator = ({ env, envLabel, version }) => (
  <div className={`environment ${env}`}>
    <span className="noWrap environmentLabel">{envLabel} </span>
    <span className="noWrap versionLabel">{`v${version}`}</span>
  </div>
);

EnvironmentIndicator.propTypes = {
  env: PropTypes.string.isRequired,
  envLabel: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default EnvironmentIndicator;
