/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import qs from 'qs';
import { withRouter } from 'next/router';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Link from '@mui/material/Link';
import DialogModal from '../common/DialogModal';
import NotificationItemComponent from './NotificationItemComponent';
import { NOTIFICATION_LINK_TYPE } from '../../utils/constants';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsEl: null,
    };
  }

  handleNotificationsClick = (event) => {
    this.setState({ notificationsEl: event.currentTarget });
  };

  getNotificationLink = (item) => {
    const { workorderID, link, eventType } = item;
    const type = get(link, 'type', 'workOrder');
    const id = get(link, 'id', workorderID);
    if (!id) return null;

    switch (type) {
      case NOTIFICATION_LINK_TYPE.WORK_ORDER:
        if (eventType === 'MESSAGE_RECEIVED') {
          return {
            pathname: '/submissions/[id]',
            query: { id, activeTab: 'messages' },
          };
        }
        return { pathname: '/submissions/[id]', query: { id } };
      case NOTIFICATION_LINK_TYPE.TASK:
        return { pathname: '/tasks', query: { filters: qs.stringify({ id }) } };
      case NOTIFICATION_LINK_TYPE.MY_TASK:
        return {
          pathname: '/mytasks',
          query: { filters: qs.stringify({ id }) },
        };
      case NOTIFICATION_LINK_TYPE.WORK_ORDER_CLASSIFICATION_TAB:
        return {
          pathname: '/submissions/[id]',
          query: { id, activeTab: 'classification' },
        };
      default:
        return {};
    }
  };

  handleNotificationsClose = (item) => {
    const { router } = this.props;
    if (typeof item.id === 'number') {
      const { viewNotification } = this.props;
      viewNotification(item.id);
    }
    this.setState({ notificationsEl: null });

    const link = this.getNotificationLink(item);
    if (link) {
      router.push(link);
    }
  };

  handleClearAll = () => {
    this.props.clearAllNotifications();
  };

  handleClearAllRead = (e) => {
    e.preventDefault();
    this.props.clearAllNotifications({ onlyRead: true });
  };

  render() {
    const { notificationsEl } = this.state;
    const { actives, notifications } = this.props;
    return (
      <Fragment>
        <IconButton
          color="inherit"
          className="mr-2"
          onClick={this.handleNotificationsClick}
          size="large"
        >
          <Badge
            badgeContent={actives > 99 ? '99+' : actives}
            color="error"
            aria-owns={notificationsEl}
            aria-haspopup="true"
            overlap="rectangular"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          className="mt-5"
          id="notifications"
          anchorEl={notificationsEl}
          open={!!notificationsEl}
          onClose={this.handleNotificationsClose}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={
            notifications.length > 0
              ? {
                sx: {
                  width: '45vw',
                  height: '90vh',
                },
              }
              : undefined
          }
        >
          {notifications.length > 0 ? (
            <div>
              <div className="py-2 px-1 notificalonControls">
                <Link
                  href="#"
                  onClick={this.handleClearAllRead}
                  variant="body1"
                  underline="hover"
                >
                  <DoneIcon className="iconLeft" />
                  Clear All Read Notifications
                </Link>
                <DialogModal
                  id="reset-notifications"
                  title="Clear All Notifications"
                  agreeLabel="Clear"
                  disagreeLabel="Cancel"
                  description="Are you sure you would like to clear all notifications?"
                  onAgree={() => this.handleClearAll()}
                  withAgree
                  withDisagree
                  toggle={(show) => (
                    <Link
                      href="#"
                      onClick={() => show()}
                      variant="body2"
                      underline="hover"
                    >
                      <DeleteForeverIcon className="iconLeft" />
                      Clear All Notifications
                    </Link>
                  )}
                />
              </div>
              {notifications.map((item, i) => (
                <NotificationItemComponent
                  key={i}
                  id={item?.id}
                  isViewed={item?.isViewed}
                  createdAt={item?.createdAt}
                  message={item?.message}
                  title={item?.title}
                  level={item?.level}
                  onClick={this.handleNotificationsClose}
                />
              ))}
            </div>
          ) : (
            <MenuItem onClick={this.handleNotificationsClose}>
              <i>There are no notifications!</i>
            </MenuItem>
          )}
        </Menu>
      </Fragment>
    );
  }
}

export default withRouter(Notifications);
