import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import LinkButton from '../common/LinkButton';

const ContentPlaceholder = ({ variant }) => (
  <Stack justifyContent="center" alignItems="center" sx={{ height: '30vh' }} color="link.muted">
    {variant === 'loading' && (
      <CircularProgress fontSize="large" role="progressbar" />
    )}
    {variant === 'error' && (
      <Stack alignItems="center" spacing={2}>
        <CloudOffIcon fontSize="large" />
        <Typography variant="h4">Error occurred</Typography>
        <LinkButton href="/">Go to Home page</LinkButton>
      </Stack>
    )}
    {variant === 'unauthorised' &&
      <Stack alignItems="center" spacing={2}>
        <PanToolIcon fontSize="large" />
        <Typography variant="h4">Unauthorized</Typography>
        <LinkButton href="/">Go to Home page</LinkButton>
      </Stack>
    }
    {variant === 'notfound' && (
      <Stack alignItems="center" spacing={2}>
        <NotInterestedIcon fontSize="large" />
        <Typography variant="h4">Not Found</Typography>
        <LinkButton href="/">Go to Home page</LinkButton>
      </Stack>
    )}
    {variant === 'closed' && (
      <Stack alignItems="center" spacing={2}>
        <NotInterestedIcon fontSize="large" />
        <Typography variant="h4">Closed content</Typography>
        <LinkButton href="/">Go to Home page</LinkButton>
      </Stack>
    )}
  </Stack>
);

ContentPlaceholder.propTypes = {
  variant: PropTypes.oneOf(['error', 'loading', 'unauthorised', 'notfound', 'closed']).isRequired,
};

export default ContentPlaceholder;
